/* eslint-disable require-jsdoc */
import React from 'react';
import {navigateTo} from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' +
                    encodeURIComponent(data[key]))
      .join('&');
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange( e ) {
    this.setState({[e.target.name]: e.target.value});
  };

  handleSubmit( e ) {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
        .then(() => navigateTo(form.getAttribute('action')))
        .catch((error) => alert(error));
  };

  render() {
    return (
      <div className="container">
        <div className="container bg-white rounded border-4 border-teal-300 \
          flex justify-center text-gray-700 shadow flex justify-center p-3
          lg:max-w-md max-w-xs"
        >
          <form
            name="contactform"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <h1 className="flex-none font-semibold font-serif text-xl \
              my-4 text-center">
              About You
            </h1>
            {/* The `form-name` hidden field is required to support form \
                submissions without JavaScript */}
            <input type="hidden" name="form-name" placeholdervalue="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="mb-4">
              <label className="block my-2">
                What&apos;s Your Name?
              </label>
              <input type="text" name="name" onChange={this.handleChange}
                className="text-field text-lg mt-2"/>
            </div>
            <div className="mb-4">
              <label className="block my-2">
                What&apos;s Your Email Address?<br />
                <input type="email" name="email" onChange={this.handleChange}
                  className="text-field text-lg mt-2"/>
              </label>
            </div>
            <div className="mb-10">
              <label className="block my-2">
                In your own words, what do you want to achieve with Daily Coach?
                <textarea name="message" onChange={this.handleChange}
                  className="text-field border-0 text-lg mt-2" />
              </label>
            </div>
            <div className="flex justify-center">
              <button className="btn-cta px-10" type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
