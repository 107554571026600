import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactForm from '../components/contactform/contactform';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import BackgroundImage from '../images/headinclouds.jpg';


const Contact = () => (
  <Layout>
    <SEO title="Sign Up" />
    <div className="bg-cover bg-center py-10 min-h-screen container md:max-w-5xl"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}>
      <div className="m-5">
        <ContactForm/>
      </div>
    </div>
  </Layout>
);

export default Contact;

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "mountainsun.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1280 srcSetBreakpoints: [600, 768, 1024, 1280 ]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) =>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        objectFit="cover"
      />}
  />
);
